/* ホーム画面のCSS Module */
.home {
  width: 560px;
  margin: auto;
  text-align: center;
}

.homeNarrow {
  @extend .home;
  width: 360px;
}

.wave {
  z-index: -1;
  position:absolute;
  top:-50px;
  width: 100vw;
  height: 180px;
  right: calc(50% - 50vw);
  left: calc(50% - 50vw);
}

.waveMobile {
  @extend .wave;
  height: 140px;
}

.contentListTitle {
  background-image: url("../resources/images/title.png");
  background-size: 100%;
  background-position: center center;
  position: relative;
  width: 320px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #191919;
  font-size: 25px;
  span {
    top: 10%;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .titleInputText {
    text-align: center;
    width: 100%;
    height: 55%;
    line-height: 40px;
    top: 16%;
    position: absolute;
    border-radius: 4px;
    text-decoration: underline;
  }
}

.contentListTitleMobile {
  @extend .contentListTitle;
  width: 232px;
  height: 52px;
  font-size: 16px;
  span {
    top: 16%;
  }
  .titleInputText{
    font-size: 16px;
  }
}

.tana3x3 {
  list-style: none;
  background-image: url("../resources/images/tana.png");
  background-size: 100%;
  background-position: center;
  width: 560px;
  height: 560px;
  margin-right: auto;
  margin-left: auto;
}

.tana3x3Narrow {
  @extend .tana3x3;
  width: 360px;
  height: 360px;
}

@mixin contentItem($side: 130px) {
  width: $side;
  height: $side;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.contentListCarousel {
  position: relative;
}

.tana3x3 li {
  @include contentItem;
  margin-top: 21px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tana3x3Narrow li {
  @include contentItem($side:90px);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.tana3x3 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tabBox {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.tabs {
  // Material-UIのスタイルを強制的に上書きするために!importantをつけている
  // !important以外で良い方法があればそちらに書き直す
  min-height: 0 !important;
  transition: none !important;
}

.addTab {
  height: 28px;
  font-weight: bold;
  font-size: 20px;
  padding: 0 5px;
}

.tabLine {
  height: 28px;
  border-bottom: 3px solid rgba(31, 81, 154, 1) !important;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  z-index: -1;
}

.tab {
  background-color: rgba(31, 81, 154, 1) !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: rgba(255, 255, 255, 1) !important;
  min-height: 0 !important;
  height: 28px !important;
  line-height: 28px !important;
  padding: 0 5px !important;
  margin: 0 1px !important;
  position: relative !important;
  border-radius: 10px 10px 0 0 !important;
  border-top: 3px solid rgba(31, 81, 154, 1) !important;
  border-left: 3px solid rgba(31, 81, 154, 1) !important;
  border-right: 3px solid rgba(31, 81, 154, 1) !important;
  float: left;
}

.tabActive {
  // @extendは既存のスタイルを流用するSCSSの記法
  @extend .tab;
  background-color: rgba(255, 255, 255, 1) !important;
  color: rgba(0, 0, 0, 1) !important;
}

.tabList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5%;
}

.contentListMenuBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userInfo {
  display: flex;
  margin-top: 80px;
  height: 82px;
  margin-bottom: 56px;
  width: 100%;
  word-wrap: break-word;
  box-sizing: border-box;
  position: relative;
  .avatar {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    margin:0px 20px;
  }
  .defaultAvatar {
    @extend .avatar;
    border: solid 3px rgba(112, 112, 112, 1);
  }
  .userName {
    // 余裕があればフォントサイズをremにしたい
    font-size: 21px;
    font-weight: bold;
    word-break: break-all;
    margin-top: 5px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .twitterId {
    position: absolute;
    font-size: 14px;
    left: 120px;
    top: 35px;
    text-align: center;
    span {
      color: #a6bab9;
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .randomButton {
    position: absolute;
    top: 100px;
    left: 0px;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
  }
}

.userInfoMobile {
  @extend .userInfo;
  margin-top: 64px;
  margin-bottom: 10px;
  .userName {
    // 余裕があればフォントサイズをremにしたい
    font-size: 16px;
    font-weight: bold;
    word-break: break-all;
    text-align: center;
    margin-left: 0px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .twitterId {
    position: absolute;
    font-size: 10px;
    color: #a6bab9;
    left: 90px;
    top: 35px;
    text-align: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .avatar{
    margin: 0px 10px;
  }
}

.setting {
  width: 50px;
  height: 50px;
}

.snsLink {
  text-align: right;
  margin-bottom: 5px;
}

.contentsMenu {
  margin: auto;
  width: 112px;
}

.contentsMenuHidden {
  @extend .contentsMenu;
  visibility: hidden;
}

.socialMenu {
  @extend .contentsMenu;
  margin-right: 0px;
  margin-bottom: 0px;
  height: 46px;
}

.socialMenuHidden {
  @extend .socialMenu;
  visibility: hidden;
}

.tutorial{
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0px;
}

.tutorial span{
  color:#e7b345;
}
