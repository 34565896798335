@font-face {
  font-family: "A-OTF-UDShinGoNTPro-Heavy";
  src: url("../resources/fonts/A-OTF-UDShinGoNTPro-Heavy.otf")
    format("opentype");
}

// 共通のスタイルを定義するSCSSの記法
@mixin boxBorder($color: rgba(112, 112, 112, 1), $bRadius: 0px) {
  box-shadow: 0px 0px 30px #00000038;
  border-radius: $bRadius !important;
}

.modalDialog {
  text-align: center;
  padding: 0 !important;
  font-size:20px;
  line-height: 30px;
}

.modalDialogMobile {
  @extend .modalDialog;
  font-size: 15px;
  line-height: 20px;
}

.dialogPaper {
  box-shadow: 0px 0px 30px #00000038 !important;
  border-radius: 0px !important;
  margin: 0px !important;
  width: 530px;
}

.dialogPaperMobile {
  @extend .dialogPaper;
  width: 360px;
}

.dialogTitle {
  font-weight: bold !important;
  font-size: 23px !important;
  padding:12px 20px !important;
  span{
    font-size: 20px;
  }
}

.dialogTitleWithWebFonts{
  @extend .dialogTitle;
  font-family: a-otf-ud-shin-maru-go-pr6n !important;
  font-weight: 300 !important;
  font-style: normal;
  text-align: left;
}

.dialogContent {
  padding: 0px !important;
}

.dialogText {
  white-space: pre-wrap;
  font-weight: bold !important;
  color: rgba(112, 112, 112, 1);
  padding:12px 20px !important;
}

.dialogTextWithWebFonts{
  @extend .dialogText;
  font-family: a-otf-ud-shin-maru-go-pr6n;
  font-weight: 300;
  font-style: normal;
  text-align: left;
}

.dialogTextTermOfUse {
  @extend .dialogText;
  font-size: 14px;
  text-align: left;
}

.dialogActions {
  justify-content: center !important;
}

@mixin commonButton(
  $color: rgba(25, 25, 25, 1),
  $backColor: rgba(255, 255, 255, 1)
) {
  background-color: $backColor;
  color: $color;
  font-family: "A-OTF-UDShinGoNTPro-Heavy", sans-serif;
  font-size: 18px;
  letter-spacing: 10px;
  text-indent: 10px;
  width: 150px;
  height: 36px;
  box-shadow: 0px 0px 2px 1px rgba(112, 112, 112, 0.5);
}

.actionButton {
  @include commonButton($backColor: rgba(25, 25, 25, 1), $color: rgba(255, 255, 255, 1));
  margin: 20px 10px;
}

.actionButtonBlack {
  @include commonButton(
    $backColor: rgba(25, 25, 25, 1),
    $color: rgba(255, 255, 255, 1)
  );
  margin: 20px 10px;
}

.actionButtonBlackDisabled {
  @include commonButton(
    $backColor: rgb(99, 99, 99),
    $color: rgb(212, 212, 212)
  );
  margin: 20px 10px;
}

.cancelButton {
  @include commonButton;
  margin: 20px 10px;
  letter-spacing: 0px;
}

.searchButton {
  @include commonButton;
  width: 100px;
  margin: 5px 10px;
}

.centerButton {
  @include commonButton($backColor: #e60623, $color: rgba(255, 255, 255, 1));
  vertical-align: middle;
}

.editOkButton {
  @include commonButton($backColor: #e60623, $color: rgba(255, 255, 255, 1));
  width: 190px;
  height: 46px;
  margin: 0px;
  font-size: 25px;
  line-height: 46px;
}

.editOkButtonMobile {
  @include commonButton($backColor: #e60623, $color: rgba(255, 255, 255, 1));
  width: 130px;
  height: 40px;
  margin: 0px;
  font-size: 20px;
  line-height: 40px;
}

.emptyContent {
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.addButton:hover {
  color: rgba(255, 0, 0, 1);
}

.notes {
  font-size: 15px;
  color: rgba(112, 112, 112, 1);
}

.addContentNotes {
  @extend .notes;
  margin-bottom: 20px;
  margin-left: 20px;
  text-align: left;
  white-space: pre-wrap;
}

.inputText {
  width: 80%;
  box-shadow: inset 0px 0px 3px #2a33334d !important;
  border: 2px solid #d2dcdc !important;
  border-radius: 4px;
}

.iconButton {
  width: 46px;
  height: 46px;
}

.iconButtonMobile {
  width: 30px;
  height: 30px;
}

.iconHidden {
  @extend .iconButton;
  visibility: hidden;
}

.contentBox {
  position: relative;
  width: 100%;
  height: 100%;
}

.editableContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #363c49;
}

.editableContent .deleteButton {
  margin: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.whiteOut {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.snsButton {
  width: 30px;
  height: 30px;
  margin: 0 3px;
}

.snsInput {
  text-align: center;
  margin: 20px 0px;
}

.snsIcon {
  width: 50px;
  height: 50px;
  margin: 0 3px;
  display: inline-block;
  vertical-align: middle;
}

.snsInputText {
  @extend .inputText;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.tabInputText {
  @extend .inputText;
  height: 28px;
  width: 100px;
}

.nameInputText {
  @extend .inputText;
  width: 180px;
  text-align: center !important;
}

.searchBar {
  background-color: rgba(255, 255, 255, 1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.searchCategories {
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
  border: 2px solid #d2dcdc;
  border-radius: 4px;
  vertical-align: middle;
  height: 34px;
  width: 180px;
  font-size: 15px;
  box-shadow: inset 0px 0px 3px #2a33334d;
}

.searchCategoriesMoblie {
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
  border: 2px solid #d2dcdc;
  border-radius: 4px;
  height: 34px;
  font-size: 15px;
  display: flex;
  margin-left: 20px;
  margin-top:20px;
  box-shadow: inset 0px 0px 3px #2a33334d;
}

.searchInputText {
  @extend .inputText;
  width: 200px;
  vertical-align: middle;
}

.urlInputText {
  @extend .inputText;
  width: 380px;
  vertical-align: middle;
}

.urlInputTextMobile {
  @extend .inputText;
  width: 200px;
  vertical-align: middle;
}

.searchResults {
  list-style-type: none;
  width: 530px;
  margin:auto;
  text-align: center;
  li {
    width: 130px;
    height: 130px;
    margin: 10px;
    display: inline-block;
  }
}

.searchResultsMobile {
  @extend .searchResults;
  width: 360px;
  li {
    width: 100px;
    height: 100px;
    margin: 10px;
    display: inline-block;
  }
}

.searchResultsImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.separatorLine {
  border-bottom: 3px solid rgba(112, 112, 112, 1);
}

.dotsPagination {
  display: flex;
  margin-bottom: 10px;
}

.dots {
  margin: 0px 5px;
  cursor: pointer;
}

.appHeader {
  background-color: rgba(0, 0, 0, 1) !important;
}

.headerToolbar{
  min-height: 47px !important;
}

.headerContainer {
  width: 560px !important;
  height: 47px !important;
  padding: 0px !important;
  .appLogo {
    width: 164px;
    height: 47px;
    margin-right: 10px;
  }
  .appName {
    font-size: 1rem !important;
    cursor: pointer;
  }
  .menuButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 47px;
  }
}

.headerContainerMobile {
  width: 360px !important;
  height: 47px !important;
  padding: 0px !important;
  font-size: 1.2rem !important;
  .appLogo {
    width: 164px;
    height: 47px;
  }
  .appName {
    font-size: 1rem !important;
  }
  .menuButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 47px;
  }
}

@mixin arrow() {
  width: 40px;
  height: 67px;
}

.arrowRight {
  @include arrow();
  position: absolute;
  bottom: 50%;
  right: 0px;
}

.arrowLeft {
  @include arrow();
  position: absolute;
  bottom: 50%;
  left: 0px;
}

.tweetButton {
  height: 55px;
}
.tweetButtonMobile {
  width: 280px;
}

.copyBar {
  .inputText {
    display: inline-block;
    vertical-align: middle;
    height: 34px;
    width: 300px;
  }
  .copyButton {
    display: inline-block;
    vertical-align: middle;
    width: 120px;
    height: 42px;
    margin: 0px;
  }
}

.copyBarMobile {
  @extend .copyBar;
  .inputText {
    width: 160px;
    font-size: 12px;
  }
}

.menuBox {
  border-radius: 0px;
}

.menuDeleteAccount {
  color: #e60623 !important;
}
