.login {
  width: 560px;
  margin: auto;
  text-align: center;
  font-family: a-otf-ud-shin-maru-go-pr6n;
  font-weight: 300;
  font-style: normal;

  .login::--webkit-scrollbar{
    display: none;
  }

  .welcomeImg {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    object-fit: cover;
  }
  .textBand{
    position: absolute;
    top: 110px;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    height: 320px;
    background-color: rgba(255, 255, 255, 0.5);
    filter: grayscale(0.5);
    left: 50%;
    transform: translateX(-50%);
  }
  .explainText {
    white-space: pre-wrap;
    text-align: left;
    position: absolute;
    top: 110px;
    width: 560px;
    height: 320px;
    font-weight: bold;
    font-size: 22px;
    justify-content: center;
    align-items: center;

    .appName{
      color: rgba(255, 255, 255, 1);
      font-size: 25px;
      letter-spacing: 10px;
      margin: 10px 40px
    }

    .aboutApp{
      margin: 10px 45px;
      font-size: 18px;
      border-left: thick solid rgba(200, 200, 200, 1);;
      padding-left: 30px;
      line-height: 30px;
    }
  }
}

.loginNarrow {
  @extend .login;
  width: 360px;

  .welcomeImg {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .explainText {
    top: 110px;
    width: 360px;
    height: 300px;
    .appName {
      color: rgba(255, 255, 255, 1);
      font-size: 22px;
      letter-spacing: 10px;
      margin: 10px 20px
    }
    .aboutApp{
      font-size: 16px;
      margin: 10px 25px;
      padding-left: 15px;
    }
  }
}

.explainTextNarrow {
  @extend .explainText;
  width: 360px;
}

.twitterLogin{
  width:460px;
  position: absolute;
  top: 470px;
  left: 50%;
  transform: translateX(-50%);
  img{
    width: 100%;
  }
}

.twitterLoginMobile{
  @extend .twitterLogin;
  width: 300px;
}

// todo シノペ 
.detailButton {
  display: flex;
  position: absolute;
  top: 600px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  text-decoration: underline;
  font-weight: bold;
  white-space: nowrap;
}

.contactButton {
  display: flex;
  position: absolute;
  top: 650px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  text-decoration: underline;
  font-weight: bold;
  white-space: nowrap;
}

.termOfUseButton {
  display: flex;
  position: absolute;
  top: 700px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  text-decoration: underline;
  font-weight: bold;
  white-space: nowrap;
}

.loginNote {
  display: flex;
  position: absolute;
  font-size: 14px;
  top: 540px;
  left: 50%;
  transform: translateX(-50%);
  white-space: pre-wrap;
  text-align: left;
}

.loginNoteMobile {
  display: flex;
  position: absolute;
  font-size: 12px;
  top: 520px;
  white-space: pre-wrap;
  text-align: left;
}
